import React, { useEffect, useState } from 'react';
import Moment from 'moment'
import config from '../config.json';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, CardContent, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { faClose, faAdd } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';


const DashboardWrapper = styled.div`
  .dashboardGradientCard {
    height: 110px;
  }

  .font07 {
    font-size: 0.7rem;
  }  

  .dashboardCardHeight {
    height: 100% !important;
  }
`;

const TableWrapper = styled.div`
   
    .materialTableStyle {
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                     .actionListEmailIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListEmailIconPosition {
            position: absolute;
            top: -5px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
      
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
       }
       .dialogStyle {
        height : 150px ;
        width : 1000px ;
    }
`;
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


export default function Dashboard() {
    const classes = useStyles();
    var [dashboardCount, setDashboardCount] = React.useState({});
    var [totalCount, setTotalCount] = React.useState(0);
    var [orders, setOrders] = useState([]);
    var [enrollments, setEnrollments] = useState([]);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [disable, setDisable] = React.useState(false);
    var [open, setOpen] = React.useState(false);
    var [emailarray, setEmailArray] = React.useState([]);
    var [displayEmails, setDisplayEmails] = React.useState();
    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [transactionId, setTransactionId] = React.useState();
    const eoptions = {
        chart: {
            type: 'pie',
        },
        labels: ['Completed', 'In Progress', 'Error'],
        dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            }
        }
    };
    const eseries = [dashboardCount.enrollmentCompleted, dashboardCount.enrollmentInProgress, dashboardCount.enrollmentError]

   const mseries = [dashboardCount.monthlyEnrollmentCompleted, dashboardCount.monthlyEnrollmentInProgress, dashboardCount.monthlyEnrollmentError]
    const moptions = {
        chart: {
            type: 'pie',
        },
        labels: ['Completed', 'In Progress', 'Error'],
        dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            }
        }
    };


    useEffect(() => {
        const MOBILESURETY_URL = config.API_URL + "/api/Dashboard/count"
        const token = config.TOKEN
        axios.get(MOBILESURETY_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    dashboardCount = res.data.data
                    setDashboardCount(dashboardCount);
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })

        const postData = {
            "isDashboard": true
        }

        const MOBILESURETY_ENROLL_URL = config.API_URL + "/api/Dep/enrollments"
        axios.post(MOBILESURETY_ENROLL_URL, postData, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    enrollments = res.data.data
                    setEnrollments(enrollments);
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
        const data = {
            "isDashboard": true
        }

        const MOBILESURETY_ORDER_URL = config.API_URL + "/api/Dep/orders"
        axios.post(MOBILESURETY_ORDER_URL, data, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {

                if (res.data.successCode === 0) {
                    orders = res.data.data
                    setOrders(orders);
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }, []);


    const OrderHeader = {
        header: ['Order Id', 'Customer Id', 'P.O. Number', 'Account Rep', 'Customer Email', 'Order Date']
    }

    const EnrollmentHeader = {
        header: ['Id', 'Order Number', 'Order Type', 'Transaction Id', 'Status', 'Status Message', 'Created']
    }


    const handleClickOpen = (id) => {
        setTransactionId(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const sendEmail = () => {
        if (email != '') {
            notifyClickAdd()
        } else {
            setDisable(true)
            const MOBILESURETY_URL = config.API_URL + "/api/Dep/transaction/email/json"
            const token = config.TOKEN
            const postData = {
                "transactionId": transactionId,
                "emailList": emailarray
            }
            axios.post(MOBILESURETY_URL, postData, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    {
                        if (res.data.successCode === 0) {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        Email sent successfully.
                                    </div>
                                </div>)
                        } else {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        "An error occured, contact support".
                                    </div>
                                </div>)
                        }
                        setTimeout(() => { setMessage(""); setOpen(false); }, 4000)
                        emailarray = []
                        setEmailArray(emailarray)
                        setDisable(false)

                    }
                })
        }
    }

    const notifyClickAdd = () => toast("You forgot to click the add button!");

    const addemail = () => {
        if (!email) {
            setEmailError("Enter Email Id!")
            setTimeout(() => { setEmailError("") }, 4000)
        } else {
            emailarray.push(email)
            setEmailArray(emailarray)
            setEmail('')
            setDisplayEmails(true)
        }
    }

    const deleteemail = (i) => {
        setEmailArray((emailarray) => {
            return emailarray.filter((arrElem, index) => {
                return index !== i;
            });
        });
    }

    const displayemail = () => {
        if (displayEmails)
            return (
                <Grid container spacing={4} className="pt-4">
                    {emailarray?.map((e, index) => {
                        return (
                            <Grid item xs={12} sm={12} md={12} key={e} className="px-5">
                                <Card style={{ backgroundColor: "#F0F0F0" }} >
                                    <CardContent className="d-flex justify-content-between pb-0">
                                        <div>
                                            <p style={{ wordBreak: "break-word" }}>{e}</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={() => deleteemail(index)} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    }
                    )}
                </Grid >
            )
    }

    return (
        <DashboardWrapper>
            <TableWrapper>
                <div className={`${classes.root} materialTableStyle`}>
                    <ToastContainer theme="light" />
                    {message}
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3} md={3}>
                            <Card className="card-box bg-premium-dark dashboardGradientCard border-0 text-light mb-4">
                                <CardContent className="p-3">
                                    <div className="d-flex align-items-start">
                                        <div className="font-weight-bold">
                                            <small className="text-white-50 d-block mb-1 text-uppercase">
                                                Completed
                                            </small>
                                            <span className="font-size-xxl mt-1">{dashboardCount.enrollmentCompleted}</span>
                                        </div>
                                        <div className="ml-auto">
                                            <div className="bg-white text-center text-success d-50 rounded-circle d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faChartBar} />
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Card className="card-box noShadow bg-asteroid dashboardGradientCard border-0 text-light mb-4">
                                <CardContent className="p-3">
                                    <div className="d-flex align-items-start">
                                        <div className="font-weight-bold">
                                            <small className="text-white-50 d-block mb-1 text-uppercase">
                                                In Progress
                                            </small>
                                            <span className="font-size-xxl mt-1">{dashboardCount.enrollmentInProgress}</span>
                                        </div>
                                        <div className="ml-auto">
                                            <div className="bg-white text-center text-success d-50 rounded-circle d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faChartBar} />
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Card className="card-box noShadow bg-midnight-bloom dashboardGradientCard text-light mb-4">
                                <CardContent className="p-3">
                                    <div className="d-flex align-items-start">
                                        <div className="font-weight-bold">
                                            <small className="text-white-50 d-block mb-1 text-uppercase">
                                                Error
                                            </small>
                                            <span className="font-size-xxl mt-1">{dashboardCount.enrollmentError}</span>
                                        </div>
                                        <div className="ml-auto">
                                            <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faChartBar} />
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={3} md={3}>
                            <Card className="card-box noShadow bg-night-sky dashboardGradientCard text-light mb-4">
                                <CardContent className="p-3">
                                    <div className="d-flex align-items-start">
                                        <div className="font-weight-bold">
                                            <small className="text-white-50 d-block mb-1 text-uppercase">
                                                Total
                                            </small>
                                            <span className="font-size-xxl mt-1">{dashboardCount.order}</span>
                                        </div>
                                        <div className="ml-auto">
                                            <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                                                <FontAwesomeIcon icon={faChartBar} />
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={6} >
                            <Card className="card-box noShadow dashboardCardHeight mb-2">
                                <div className="card-header pr-5">
                                    <div className="card-header--title">Enrollment Details</div>
                                </div>
                                    <CardContent className="p-3">
                                        <Chart options={eoptions} type="pie" series={eseries} width="320" />
                                    </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Card className="card-box noShadow dashboardCardHeight mb-4">
                                <div className="card-header pr-2">
                                    <div className="card-header--title">YTD Enrolled Devices</div>
                                </div>
                                <CardContent className="p-3">
                                    <Chart options={moptions} type="pie" series={mseries} width="320" />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                   

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Card className="card-box noShadow dashboardCardHeight mb-4">
                                <div className="card-header pr-2">
                                    <div className="card-header--title">Orders
                                    </div>
                                </div>
                                <CardContent className="p-3">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-hover text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    {OrderHeader.header.map((headerItem, index) => (
                                                        <th className="text-left" key={index}>{headerItem}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orders.map((order, index) => (
                                                    <tr key={index}>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{order.id}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{order.customerId}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{order.poNumber}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{order.accountRep}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{order.customerEmail}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{order.orderDate = order.orderDate ? Moment(Date.parse(order.orderDate)).format('MM/DD/YYYY') : ""}</div>
                                                        </td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardContent>
                                <div className="card-footer d-flex justify-content-between text-right">
                                    <div className="w-100">
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            className="mr-3 cancelBtn"
                                            color="primary"
                                            onClick={() => navigate('/order/list')}>
                                            View all
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Card className="card-box noShadow mb-4">
                                <div className="card-header pr-2">
                                    <div className="card-header--title">Enrollments</div>
                                </div>
                                <CardContent className="p-3">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-hover text-nowrap mb-0">
                                            <thead>
                                                <tr>
                                                    {EnrollmentHeader.header.map((headerItem, index) => (
                                                        <th className="text-left" key={index}>{headerItem}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {enrollments.map((enrollment, index) =>

                                                (
                                                    <tr key={enrollment.id}>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{enrollment.id}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{enrollment.orderNumber}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{enrollment.orderType}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{enrollment.transactionId}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{enrollment.status}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{enrollment.statusMessage}</div>
                                                        </td>
                                                        <td className="text-left">
                                                            <div className="text-black font07">{enrollment.createdDate = enrollment.createdDate ? Moment(Date.parse(enrollment?.createdDate)).format('MM/DD/YYYY') : ""}
                                                                <div className="actionListEmailIconPosition">
                                                                    <Tooltip title="Email" aria-label="Email">
                                                                        <IconButton className="actionBtn" aria-label="Email" onClick={() => handleClickOpen(enrollment.id)}>
                                                                            <EmailIcon className="actionIcon" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Dialog open={open} closeonescape="true" fullWidth={true} maxWidth="sm" >
                                                                        <DialogTitle>
                                                                            <div className="text-right">
                                                                                <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={handleClose} />
                                                                            </div>
                                                                            Email
                                                                        </DialogTitle>
                                                                        <DialogContent >
                                                                            <div className="filterRowUpdate d-flex justify-content-center">
                                                                                <TextField fullWidth label="Email Id" variant="standard"
                                                                                    value={email}
                                                                                    onChange={e => setEmail(e.target.value)}
                                                                                />
                                                                                <span className="errMsg">{emailError}</span>
                                                                                <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                                                                    style={{
                                                                                        borderRadius: "50%",
                                                                                        backgroundColor: "rgb(245, 147, 0)",
                                                                                        color: "white",
                                                                                        padding: "5px",
                                                                                        fontSize: "20px",
                                                                                        cursor: "pointer"
                                                                                    }} onClick={() => addemail()} />
                                                                            </div>
                                                                            {displayemail()}
                                                                            <DialogActions>
                                                                                <Grid container spacing={4} className="mt-3">
                                                                                    <Grid item xs={12} sm={12} md={12} className="text-right">
                                                                                        <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={handleClose}>
                                                                                            Cancel
                                                                                        </Button>&nbsp;&nbsp;
                                                                                        <Button type="submit" disabled={disable} variant="contained" color="primary" className="noShadow addBtn" onClick={() => sendEmail()}>
                                                                                            Send
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </DialogActions>
                                                                            {message}
                                                                        </DialogContent>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardContent>
                                <div className="card-footer d-flex justify-content-between text-right">
                                    <div className="w-100">
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            className="mr-3 cancelBtn"
                                            color="primary"
                                            onClick={() => navigate('/order/enrollments')}>
                                            View all
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </TableWrapper>
        </DashboardWrapper>
    );
}


