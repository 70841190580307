import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Grid, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from '../../../shared/components/Loader';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
 
    position: relative;
   
    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;

export default function OverrideOrder() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    const { id } = useParams();
    const navigate = useNavigate();
    var [rows, setRows] = useState([]);
    var [devices, setDevices] = useState([]);
    var [customerInfo, setCustomerInfo] = React.useState([]);
    var [customerId, setCustomerId] = React.useState("-1");
    const [deviceId, setDeviceId] = React.useState("");
    const [assetTag, setAssetTag] = React.useState("");
    const token = config.TOKEN
    var [deviceUpdated, setDeviceUpdated] = React.useState(false);
    var [orders, setOrders] = React.useState([]);
    var [data, setData] = React.useState([]);
    var [customer, setCustomer] = React.useState([]);
    var [shipDate, setShipDate] = React.useState();
    var [orderDate, setOrderDate] = React.useState();
    const verifyDevice = () => toast("Device Already Enrolled!");
    var [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {

        isLoading = true
        setIsLoading(isLoading)
        const MOBILESURETY_ORDER_URL = config.API_URL + "/api/Dep/order/" + id
        axios.get(MOBILESURETY_ORDER_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    customerId = res.data.data?.customerInfo?.id
                    setCustomerId(customerId)
                    data = res.data.data
                    setData(data)
                    rows = res.data.data.orders
                    setRows(rows);
                    //  if (rows[0].showOrderStatusCode == null) {
                    orders = rows;
                    setOrders(orders)
                    orderDate = orders[0]?.orderDate
                    // orderDate = orders[0]?.orderDate ? Moment(Date.parse(orders[0]?.orderDate)).format('YYYY-MM-DD') : "";
                    setOrderDate(orderDate)
                    if (orders[0]?.deliveries) {
                        devices = orders[0]?.deliveries[0]?.devices
                        setDevices(devices)
                        shipDate = orders[0]?.deliveries[0]?.shipDate
                        //  shipDate = orders[0]?.deliveries[0]?.shipDate ? Moment(Date.parse(orders[0]?.deliveries[0]?.shipDate)).format('YYYY-MM-DD') : "";
                        setShipDate(shipDate)
                    }
                   
                    // } else {
                    //     setMessage(
                    //         <div className="message">
                    //             <div className="alert alert-danger" role="alert">
                    //                 "Order Not Found"
                    //             </div>
                    //         </div>)
                    //     setTimeout(() => { setMessage("") }, 5000)
                    // }
                    const MOBILESURETY_CUSTOMER_URL = config.API_URL + "/api/customerlist/basic"
                    axios.get(MOBILESURETY_CUSTOMER_URL, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {
                            if (res.data.successCode === 0) {
                                customerInfo = res.data.data
                                setCustomerInfo(customerInfo);
                                customer = customerInfo.filter(c => c.id == customerId)
                                setCustomer(customer)
                                isLoading = false
                                setIsLoading(isLoading)
                            } else {
                                setMessage(
                                    <div className="message">
                                        <div className="alert alert-danger" role="alert">
                                            "An error occured,try again later"
                                        </div>
                                    </div>)
                                setTimeout(() => { setMessage("") }, 5000)
                            }
                        })
                }
            })

    }, [])

    const addDevice = () => {
        const MOBILESURETY_DEVICE_URL = config.API_URL + "/api/Dep/validdevice/" + deviceId
        axios.get(MOBILESURETY_DEVICE_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    if (res.data.data == true) {
                        devices.push({
                            deviceId: deviceId, assetTag: assetTag
                        })
                        setDevices([...devices])
                        deviceUpdated = true
                        setDeviceUpdated(deviceUpdated)
                        setDeviceId("");
                        setAssetTag("");
                    } else {
                        verifyDevice()
                    }
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const deleteDevice = (i) => {
        devices.splice(i, 1);
        setDevices([...devices])
        deviceUpdated = true
        setDeviceUpdated(deviceUpdated)
    }

    const handleCustomer = (client) => {

        console.log(client)
        if (client != null) {
            customerId = client.id;
            setCustomerId(client.id)
        } else {
            customerId = "-1"
            setCustomerId(customerId)
        }
    }

    const orderTable = () => {
        return (
            <Grid item xs={12} sm={12} md={12}>
                <table className="table table-bordered p-5" >
                    <thead >
                        <tr>
                            <th scope="col" style={{ background: "#ffffff" }}>#</th>
                            <th scope="col" style={{ background: "#ffffff" }}>Device Id</th>
                            <th scope="col" style={{ background: "#ffffff" }}>Asset Tag</th>
                            <th scope="col" style={{ background: "#ffffff" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {devices?.map((d, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{d.deviceId}</td>
                                    <td>{d.assetTag}</td>
                                    <td>
                                        <div className="actionListDeleteIconPosition">
                                            <Tooltip title="delete" aria-label="delete">
                                                <IconButton className="actionBtn" aria-label="delete" onClick={(index) => deleteDevice(index)} >
                                                    <DeleteIcon className="actionIcon" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Grid>
        )
    }


    return (
        <div>
            {isLoading ? <Loader /> :
                <UserWrapper>
                    <ToastContainer theme="dark" />
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            OrderNumber: orders[0]?.orderNumber, CustomerId: customerId, OrderDate: orderDate,
                            ShipDate: shipDate, ShipTo: data?.shipTo, PoNumber: data?.poNumber
                        }}
                        validate={values => {

                            const errors = {};
                            if (!customerId) {
                                errors.CustomerId = 'Required!';
                            }


                            return errors;
                        }}

                        const handleMouseDownPwd={(event) => {
                            event.preventDefault();
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            const postData = {
                                orderNumber: values.OrderNumber,
                                orderDate: values.OrderDate,
                                shipDate: values.ShipDate,
                                shipTo: values.ShipTo,
                                poNumber: values.PoNumber,
                                customerId: customerId,
                                devices: devices,
                                deviceUpdated: deviceUpdated
                            }

                            const MOBILESURETY_URL = config.API_URL + "/api/Dep/order/override"
                            axios.post(MOBILESURETY_URL, postData, {
                                headers: {
                                    'Authorization': `Basic ${token}`
                                }
                            })
                                .then((res) => {

                                    if (res.data.successCode === 0) {
                                        setMessage(
                                            <div className="message">
                                                <div className="alert alert-success" role="alert">
                                                    "Order Updated Successfully!"
                                                </div>
                                            </div>)
                                    } else {
                                        setMessage(
                                            <div className="message">
                                                <div className="alert alert-danger" role="alert">
                                                    "An error occured,try again later"
                                                </div>
                                            </div>)
                                    }
                                    setTimeout(() => { setMessage(""); navigate("/order/list") }, 2000)

                                })
                            setSubmitting(false)
                        }}

                    >

                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,

                        }) => (
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="mt-3">
                                    <div className="wrapperWhite">
                                        <form onSubmit={handleSubmit} >

                                            {message}
                                            <Grid container spacing={4} className='pb-3'>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <PageTitle>Override DEP Enrollment</PageTitle>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} className="text-right">

                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={4}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField label="Order Number" variant="outlined" value={values.OrderNumber || ''} onBlur={handleBlur} name="OrderNumber" disabled={true} onChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Autocomplete
                                                        options={customerInfo}
                                                        className="filterAutocomplete"
                                                        getOptionLabel={(option) => option.name || ''}
                                                        onChange={(event, client) => {
                                                            handleCustomer(client)
                                                        }}
                                                        name="CustomerId"
                                                        value={customer[0] || null}
                                                        renderInput={(params) => <TextField  {...params} fullWidth label="Select Customer" className="filter" variant="outlined" />
                                                        }
                                                    />
                                                    <span className="errMsg">{errors.CustomerId && touched.CustomerId && errors.CustomerId}</span>
                                                </Grid>
                                                {/* <Grid item xs={12} sm={12} md={12}>
                                            <TextField label="Email" variant="outlined" value={values.QbName} onBlur={handleBlur} name="QbName" onChange={handleChange} />
                                            <span className="errMsg">{errors.QbName && touched.QbName && errors.QbName}</span>
                                        </Grid> */}
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField label="P.O. Number" variant="outlined" value={values.PoNumber || ''} onBlur={handleBlur} name="PoNumber" onChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        id="Date"
                                                        label="Order Date"
                                                        name="OrderDate"
                                                        type="date"
                                                        color="primary"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.OrderDate || ''}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12} sm={12} md={12}>
                                            <TextField label="Delivery Number" variant="outlined" value={values.City} onBlur={handleBlur} name="City" onChange={handleChange} />
                                        </Grid> */}
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField label="Ship To" variant="outlined" value={values.ShipTo || ''} onBlur={handleBlur} name="ShipTo" onChange={handleChange} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        id="Date"
                                                        label="Delivery Date"
                                                        type="date"
                                                        color="primary"
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.ShipDate || ''}
                                                        name="ShipDate"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid container spacing={4} className='pb-3'>
                                                        <Grid item xs={12} sm={6} md={6}>
                                                            <TextField label="Device Id" variant="outlined" value={deviceId} onChange={e => setDeviceId(e.target.value)} />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={6}>
                                                            <TextField style={{ width: "80%" }} label="Asset Tag" variant="outlined" value={assetTag} onChange={e => setAssetTag(e.target.value)} />
                                                            <Tooltip title="Add Device" aria-label="Add Device">
                                                                <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                                                    style={{
                                                                        borderRadius: "50%",
                                                                        backgroundColor: "rgb(245, 147, 0)",
                                                                        color: "white",
                                                                        padding: "5px",
                                                                        fontSize: "20px",
                                                                        cursor: "pointer"
                                                                    }}
                                                                    onClick={addDevice} />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {orderTable()}

                                                {/* <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                id="comment"
                                                label="Order Notes"
                                                type="text"
                                                multiline
                                                rows="4"
                                                color="primary"
                                                variant="outlined"
                                            />
                                        </Grid> */}


                                            </Grid>
                                            <Grid container spacing={4} >
                                                <Grid item xs={12} sm={12} md={12} className="text-right mt-4">
                                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/order/list")}>
                                                        Cancel
                                                    </Button>&nbsp;&nbsp;
                                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        </form>
                                    </div>
                                </Grid>
                            </Grid>
                        )
                        }
                    </Formik >
                </UserWrapper>
            }
        </div>
    );
}
