import React, { lazy, Suspense, useState } from 'react';
import { Routes, Route, Navigate, useLocation, } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@mui/styles';
import MuiTheme from './theme';
import { LeftSidebar, PresentationLayout } from './layout-blueprints';
import { Loader } from './shared/components/Loader';
import Customers from './pages/Customers/Customers/Customers';
import Orders from './pages/Orders/Orders/Orders';
import OrderEnrollments from './pages/Enrollments/Enrollments/OrderEnrollments';
import EnrollmentsErrors from './pages/Enrollments/EnrollmentsErrors/EnrollmentsErrors';
import OrderDetails from './pages/Orders/OrderDetails/OrderDetails';
import OverrideOrder from './pages/Orders/OverrideOrders/OverrideOrder';
import UserList from './pages/Users/Users/Users';
import AddUser from './pages/Users/AddUser/AddUser';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Dashboard from './pages/Dashboard/Dashboard';

const Login = lazy(() => import('./pages/Login'));

const RoutesComponent = () => {

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">
                <Loader color="#ff9900" />
              </div>
            </div>
          }>
          <PresentationLayout>
          </PresentationLayout>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route element={<LeftSidebar />}>
              <Route path="/customer/list" element={<Customers />}></Route>
              <Route path="/order/list" element={<Orders />}></Route>
              <Route path="/order/override/:id" element={<OverrideOrder />}></Route>
              <Route path="/order/details/:id/:type" element={<OrderDetails />}></Route>
              <Route path="/order/enrollments" element={<OrderEnrollments />}></Route>
              <Route path="/enrollment/error/:id" element={<EnrollmentsErrors />}></Route>
              <Route path="/user/list" element={<UserList />}></Route>
              <Route path="/user/add" element={<AddUser />}></Route>
              <Route path="/user/edit/:id" element={<AddUser />}></Route>
              <Route path="/user/reset-password" element={<ResetPassword />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
            </Route>
            <Route path="/resetpwd/:userkey" element={<ForgotPassword />}></Route>

          </Routes>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default RoutesComponent;
