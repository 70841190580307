import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';
import { Button, } from '@mui/material';
import { FilterInput } from '../../../shared/components/FilterInput';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../shared/components/Loader';


const TableWrapper = styled.div`
    .content {
        font-size : 15px;
    }
   
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                    .actionListInfoIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListInfoIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
       
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 2fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
       
    }

    .dialogStyle {
        height : 150px ;
        width : 500px ;
    }

    .closeIcon { 
        position: absolute;
        left: 480px;
        right: 0px;
        top: 10px;
        bottom: 0px;
    }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'deviceId', numeric: false, label: 'Device Id' },
    { id: 'devicePostStatus', numeric: false, label: 'Device Status' },
    { id: 'devicePostStatusMessage', numeric: false, label: 'Device Error' },
    // { id: 'status', numeric: false, label: 'Status' },
    // { id: 'statusMessage', numeric: false, label: 'Status Message' },
    // { id: 'transactionId', numeric: false, label: 'DEP Transaction ID' },
    // { id: 'requestinguser', numeric: false, label: 'Requesting User' },
    // { id: 'created', numeric: false, label: 'Created' }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnrollmentsErrors() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [message, setMessage] = React.useState(" ");
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    var [devices, setDevices] = React.useState([]);
    var [orders, setOrders] = React.useState([]);
    var [deliveries, setDeliveries] = React.useState([]);
    var [search, setSearch] = React.useState("");
    const { id } = useParams();
    const token = config.TOKEN
    var [viewPopup, setViewPopup] = React.useState(false);
    var [isLoading, setIsLoading] = React.useState(false);


    useEffect(() => {
        getEnrollmentById()
    }, [])

    const getEnrollmentById = () => {
        isLoading = true
        setIsLoading(isLoading)
        const MOBILESURETY_ENROLLMENT_URL = config.API_URL + "/api/Dep/transaction/" + id
        axios.get(MOBILESURETY_ENROLLMENT_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(filteredRows)
                    orders = filteredRows?.orders[0]
                    setOrders(orders)
                    if (orders?.deliveries) {
                        deliveries = orders?.deliveries[0]
                        setDeliveries(deliveries)
                        if (orders?.deliveries[0]?.devices) {
                            devices = orders?.deliveries[0]?.devices
                            setDevices(devices)
                        }
                    }
                    isLoading = false
                    setIsLoading(isLoading)

                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }


    const handleSearch = (event) => {
        search = event.target.value;
        setSearch(search);
        filteredRows = rows.filter(a => a.customerdba.toLowerCase().includes(search.toLowerCase()))
        setFilteredRows(filteredRows)

    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openViewPopup = () => {
        viewPopup = true
        setViewPopup(viewPopup)
    }

    const closeViewPopup = () => {
        viewPopup = false
        setViewPopup(viewPopup)
    }


    return (
        <div>
            {isLoading ? <Loader /> :
                <TableWrapper>
                    {message}
                    <div className={`${classes.root} materialTableStyle`}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={4} md={4} >
                                <div className='content'>
                                    <b>Transaction Id</b> : {filteredRows.transactionId}  <br></br>
                                    <b>Status</b> : {filteredRows.statusCode}<br></br>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <div className='content'>
                                    <b>Order Number</b> : {orders?.orderNumber} <br></br>
                                    <div className='mt-1'>
                                        <b>Order Status </b> : {orders?.orderPostStatus} <br></br>
                                        {orders?.orderPostStatus != "COMPLETE" &&
                                            <div>
                                                <b>Enrollment Error : </b> {orders?.orderPostStatusMessage} <br></br>
                                            </div>}
                                        {/* <div className="actionListInfoIconPosition"> 
                                {orders?.orderPostStatus != "COMPLETE" && <Tooltip title="Click to view Enrollment Error" aria-label="info">
                                    <IconButton className="actionBtn" aria-label="info" onClick={openViewPopup}>
                                        <InfoIcon className="actionIcon" />
                                    </IconButton>
                                </Tooltip>}
                                <Dialog open={viewPopup} closeonescape="true" maxWidth="true" >
                                    <TableWrapper>
                                        <DialogTitle>
                                            <div className="text-right">
                                                <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={closeViewPopup} />
                                            </div>
                                        </DialogTitle>
                                        <DialogContent className="dialogStyle mt-3">
                                            <div className={`${classes.root} materialTableStyle`}>
                                                {orders?.orderPostStatusMessage}
                                            </div>
                                        </DialogContent>
                                    </TableWrapper>
                                </Dialog> */}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <div className='content'>
                                    <b>Delivery Number</b> : {deliveries?.deliveryNumber}<br></br>
                                    <b>Delivery Status</b> : {deliveries?.deliveryPostStatus} <br></br>
                                    {deliveries?.deliveryPostStatus != "COMPLETE" &&
                                        <div>
                                            <b>Delivery Error</b> : {deliveries?.deliveryPostStatusMessage} <br></br>
                                        </div>}
                                </div>
                            </Grid>
                        </Grid>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>

                                    {stableSort(devices, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((device, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={device.deviceId}
                                                >
                                                    <TableCell>
                                                        {device.deviceId}
                                                    </TableCell>
                                                    <TableCell>
                                                        {device.devicePostStatus}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {device.devicePostStatusMessage}
                                                    </TableCell>

                                                    {/* <TableCell align="left">
                                                {filteredRows.status}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.statusMessage}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.transactionId}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.requestingUser}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.created}
                                            </TableCell> */}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="paginationLabel"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={devices.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </TableWrapper>
            }
        </div>
    );
}


