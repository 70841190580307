import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Button, MenuItem, TextField, FormControl, FormControlLabel, Grid, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../shared/components/Loader';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }
    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }

    .cardStyle {
        backgroundColor : blue !important;
    }
`;

export default function AddUser() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    let tokens = localStorage.getItem("token");
    tokens = JSON.parse(tokens)
    const token = config.TOKEN;
    const { id } = useParams();
    var [portalLogin, setPortalLogin] = React.useState(false);
    const navigate = useNavigate();
    var [rows, setRows] = React.useState([
        {
            "name": "",
            "qbName": "",
            "email": " ",
            "isActive": false
        }
    ]);
    var [isActive, setIsActive] = React.useState(false);
    const [disableEmail, setDisableEmail] = React.useState(false);
    var [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {

        if (id) {
            setDisableEmail(true)
            isLoading = true
            setIsLoading(isLoading)
            const MOBILESURETY_USER_URL = config.API_URL + "/api/user/" + id
            axios.get(MOBILESURETY_USER_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        setRows(rows);
                        isActive = rows.isActive
                        setIsActive(isActive)
                        isLoading = false
                        setIsLoading(isLoading)
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                })
        }

    }, [])

    const UserTypeList = ["Admin", "Manager", "User"]

    return (
        <div>
            {isLoading ? <Loader /> :
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        Firstname: rows?.firstName, Lastname: rows?.lastName, Email: rows?.userEmail, IsActive: rows?.isActive,
                        UserType: rows?.userType ? rows?.userType : null, Password: null
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.Email) {
                            errors.Email = 'Required!';
                        }

                        return errors;
                    }}

                    const handleMouseDownPwd={(event) => {
                        event.preventDefault();
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        const postData = {
                            firstName: values.Firstname,
                            lastName: values.Lastname,
                            userEmail: values.Email,
                            isActive: isActive,
                            userType: values.UserType,
                            pwd: values.Password
                        }
                        // same api for add & edit
                        const MOBILESURETY_URL = config.API_URL + "/api/user/add"
                        axios.post(MOBILESURETY_URL, postData, {
                            headers: {
                                'Authorization': `Basic ${token}`
                            }
                        })
                            .then((res) => {

                                if (res.data.successCode === 0) {
                                    setMessage(
                                        <div className="message">
                                            <div className="alert alert-success" role="alert">
                                                {id ? "User Updated Successfully!" : "User Added successfully!"}
                                            </div>
                                        </div>)
                                } else {
                                    setMessage(
                                        <div className="message">
                                            <div className="alert alert-danger" role="alert">
                                                "An error occured,try again later"
                                            </div>
                                        </div>)
                                }
                                setTimeout(() => { setMessage(""); navigate("/user/list") }, 2000)

                            })

                        setSubmitting(false)
                    }}
                >

                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,

                    }) => (
                        <form onSubmit={handleSubmit}>
                            <UserWrapper>
                                {message}
                                <Grid container spacing={4} className='d-flex justify-content-center'>
                                    <Grid item xs={12} sm={8} md={8} className="pt-4">
                                        <PageTitle>Employee</PageTitle>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} className="pt-3">
                                        <TextField label="First Name" variant="standard" value={values.Firstname || ''} onBlur={handleBlur} name="Firstname" onChange={handleChange} />
                                        <span className="errMsg">{errors.Firstname && touched.Firstname && errors.Firstname}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} className="pt-3">
                                        <TextField label="Last Name" variant="standard" value={values.Lastname || ''} onBlur={handleBlur} name="Lastname" onChange={handleChange} />
                                        <span className="errMsg">{errors.Lastname && touched.Lastname && errors.Lastname}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} className="pt-3">
                                        <TextField label="Email" variant="standard" value={values.Email || ''} onBlur={handleBlur} name="Email" disabled={disableEmail} onChange={handleChange} />
                                        <span className="errMsg">{errors.Email && touched.Email && errors.Email}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <FormControlLabel
                                            label="Active"
                                            control={<Switch
                                                id="isactive"
                                                checked={isActive}
                                                name="IsActive"
                                                color="warning"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                onChange={(e) => setIsActive(e.target.checked)}
                                            />} >
                                        </FormControlLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} className="pt-0">
                                        <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                                            <TextField
                                                style={{ width: "99%" }}
                                                className="filterDropdown"
                                                id="status"
                                                select
                                                label="Select User Type"
                                                helperText=""
                                                variant="standard"
                                                name="UserType"
                                                value={values.UserType || ''}
                                                onBlur={handleBlur}
                                                onChange={handleChange}>
                                                {UserTypeList.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} className="pt-3">
                                        <TextField label="Password" variant="standard" value={values.Password || ''} onBlur={handleBlur} name="Password" onChange={handleChange} />
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                        <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/user/list")}>
                                            Cancel
                                        </Button>&nbsp;&nbsp;
                                        <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </UserWrapper>
                        </form>
                    )}
                </Formik>
            }</div>
    );
}
