import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from 'styled-components';
import { Button, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Grid, Checkbox } from '@mui/material'
import { useParams } from "react-router-dom";
import Moment from 'moment';
import { Loader } from '../../../shared/components/Loader';
import { FilterInput } from '../../../shared/components/FilterInput';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { faClose, faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const TableWrapper = styled.div`
    .content {
        font-size : 13px;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListEmailIconPosition {
            position: absolute;
            top: -5px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
       
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 2fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
       
    }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



const headCells1 = [
    { id: 'id', numeric: false, label: 'Trxn ID' },
    { id: 'orderType', numeric: false, label: 'Order Type' },
    { id: 'transactionId', numeric: false, label: 'DEP Transaction ID' },
    { id: 'status', numeric: false, label: 'DEP Status' },
    { id: 'pending', numeric: false, label: 'Status' },
    { id: 'dateTime', numeric: false, label: 'Date Time' },
    { id: '', numeric: false, label: '' }
];

function EnhancedTableHead1(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells1.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead1.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function OrderDetails() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [message, setMessage] = React.useState(" ");
    var [rows, setRows] = React.useState([]);
    var [orders, setOrders] = React.useState([]);
    var [customerInfo, setCustomerInfo] = React.useState([]);
    var [transactionList, setTransactionList] = React.useState([]);
    const token = config.TOKEN
    const { id } = useParams();
    const { type } = useParams();
    const navigate = useNavigate();
    var [devicesList, setDevicesList] = React.useState([]);
    var [selectAll, setSelectAll] = React.useState(false);
    var [devices, setDevices] = React.useState([]);
    var [filteredDevices, setFilteredDevices] = React.useState([]);
    var [statusCode, setStatusCode] = React.useState("");
    var [isLoading, setIsLoading] = React.useState(false);
    var [search, setSearch] = React.useState("");
    var [open, setOpen] = React.useState(false);
    var [emailarray, setEmailArray] = React.useState([]);
    var [displayEmails, setDisplayEmails] = React.useState();
    const [email, setEmail] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [transactionId, setTransactionId] = React.useState();
    const [disable, setDisable] = React.useState(false);
    const [voidPopup, setVoidPopup] = React.useState(false);


    const headCells = [
        { id: 'srno', numeric: false, label: '#' },
        { id: 'deviceId', numeric: false, label: 'Device Id' },
        { id: 'assetTag', numeric: false, label: 'Asset Tag' }
    ];

    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {type == 2 && headCell.label == '#' && <Checkbox
                                checked={selectAll}
                                style={{ color: "#F59300", paddingLeft: "13px" }}
                                onChange={(event) => selectAllRows(event)}
                            />}
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };


    useEffect(() => {
        getOrderDetail()
    }, [])

    const getOrderDetail = () => {
        isLoading = true
        setIsLoading(isLoading)
        const MOBILESURETY_ORDER_URL = config.API_URL + "/api/Dep/order/" + id
        axios.get(MOBILESURETY_ORDER_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    customerInfo = res.data.data.customerInfo
                    setCustomerInfo(customerInfo)
                    transactionList = res.data.data.transactionList
                    setTransactionList(transactionList)
                    rows = res.data.data.orders
                    setRows(rows);
                    statusCode = res.data.data.statusCode
                    setStatusCode(statusCode)
                    isLoading = false
                    setIsLoading(isLoading)
                    if (rows[0].showOrderStatusCode == null) {
                        orders = rows;
                        setOrders(orders)
                        if (orders[0]?.deliveries) {
                            filteredDevices = orders[0]?.deliveries[0]?.devices
                            setFilteredDevices(filteredDevices)
                            devices = filteredDevices
                            setDevices(devices)
                        }
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "Order Not Found"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        isSelectedCheckboxes(newPage)
    };

    const isSelectedCheckboxes = (page) => {
        const trimStart = page * rowsPerPage
        const trimEnd = trimStart + rowsPerPage
        const selectedRows = devices.slice(trimStart, trimEnd)
        if (selectedRows.every(x => x.select === true)) {
            selectAll = true
        } else {
            selectAll = false
        }
        setSelectAll(selectAll)
    }


    const selectAllRows = (event) => {

        setSelectAll(event.target.checked)
        const trimStart = page * rowsPerPage
        const trimEnd = trimStart + rowsPerPage
        for (let index = trimStart; index < trimEnd; index++) {
            let element = devices[index];
            element['select'] = event.target.checked
            devicesList.push(element?.deviceId)
            setDevicesList(devicesList)
        }
    }

    const onChangeCheckedBox = (checked, index) => {
        console.log(devices[index]);
        let pageIndex = index + (page * rowsPerPage)
        devices[pageIndex]['select'] = checked
        devicesList = devices.filter(x => x.select === true).map(d => d.deviceId)
        setDevicesList(devicesList)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOrder = () => {
        if (type == 1) {
            const MOBILESURETY_URL = config.API_URL + "/api/Dep/order/void/" + orders[0]?.orderNumber
            axios.get(MOBILESURETY_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {

                    if (res.data.successCode === 0) {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-success" role="alert">
                                    "Order Void Successfully!"
                                </div>
                            </div>)
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                    }
                    setTimeout(() => { setMessage(""); navigate("/order/list") }, 2000)
                })
        } else if (type == 2) {

            setSelectAll(false)
            for (let index = 0; index < devices.length; index++) {
                let element = devices[index];
                element['select'] = false
            }

            const MOBILESURETY_URL = config.API_URL + "/api/Dep/order/return/" + orders[0]?.orderNumber
            axios.post(MOBILESURETY_URL, devicesList, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {

                    if (res.data.successCode === 0) {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-success" role="alert">
                                    "Devices Returned Successfully!"
                                </div>
                            </div>)
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                    }
                    devicesList = []
                    setDevicesList(devicesList);
                    setTimeout(() => { setMessage(""); navigate("/order/list") }, 2000)

                })
        }
    }

    const handleSearch = (event) => {
        search = event.target.value;
        setSearch(search);
        devices = filteredDevices.filter(a => (a.deviceId.toLowerCase().includes(search.toLowerCase())) || (a.assetTag.toLowerCase().includes(search.toLowerCase())))
        setDevices(devices)
    }

    const handleClickOpen = (id) => {
        setTransactionId(id)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const sendEmail = () => {
        if (email != '') {
            notifyClickAdd()
        } else {
            setDisable(true)
            const MOBILESURETY_URL = config.API_URL + "/api/Dep/transaction/email/json"
            const token = config.TOKEN
            const postData = {
                "transactionId": transactionId,
                "emailList": emailarray
            }
            axios.post(MOBILESURETY_URL, postData, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    {
                        if (res.data.successCode === 0) {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        Email sent successfully.
                                    </div>
                                </div>)
                        } else {
                            setMessage(
                                <div className="message">
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        "An error occured, contact support".
                                    </div>
                                </div>)
                        }
                        setTimeout(() => { setMessage(""); setOpen(false); }, 4000)
                        emailarray = []
                        setEmailArray(emailarray)
                        setDisable(false)

                    }
                })
        }
    }

    const notifyClickAdd = () => toast("You forgot to click the add button!");

    const addemail = () => {
        if (!email) {
            setEmailError("Enter Email Id!")
            setTimeout(() => { setEmailError("") }, 4000)
        } else {
            emailarray.push(email)
            setEmailArray(emailarray)
            setEmail('')
            setDisplayEmails(true)
        }
    }

    const deleteemail = (i) => {
        setEmailArray((emailarray) => {
            return emailarray.filter((arrElem, index) => {
                return index !== i;
            });
        });
    }

    const displayemail = () => {
        if (displayEmails)
            return (
                <Grid container spacing={4} className="pt-4">
                    {emailarray?.map((e, index) => {
                        return (
                            <Grid item xs={12} sm={12} md={12} key={e} className="px-5">
                                <Card style={{ backgroundColor: "#F0F0F0" }} >
                                    <CardContent className="d-flex justify-content-between pb-0">
                                        <div>
                                            <p style={{ wordBreak: "break-word" }}>{e}</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={() => deleteemail(index)} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    }
                    )}
                </Grid >
            )
    }

    return (
        <div>
            <ToastContainer theme="light" />
            {isLoading ? <Loader /> :
                <TableWrapper>
                    {message}
                    <div className={`${classes.root} materialTableStyle`}>
                        <div class="d-flex justify-content-between px-3 py-3">
                            {/* <Grid container spacing={4}> */}
                            {/* <Grid item xs={12} sm={4} md={4}>
                                <div className='content'>
                                    From <br></br>
                                    <b>CDEP Team</b> <br></br>
                                    7 Ang Mo Kio Street 64 <br></br>
                                    Singapore 508968 <br></br>
                                    Phone : 9866567789 <br></br>
                                    Email : jim_lerey@apple.com <br></br>
                                </div>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={9} md={9}> */}
                            <div>
                                <p className='content'>
                                    To <br></br>
                                    <b>{customerInfo.name}</b> <br></br>
                                    {customerInfo.address}<br></br>
                                    Phone : {customerInfo.phone}<br></br>
                                    Email : {customerInfo.email} <br></br>
                                </p>
                            </div>
                            {/* </Grid>
                            <Grid item xs={12} sm={3} md={3}> */}
                            <div>
                                <p className='content pt-2'>
                                    <b>Purchase Order : #{orders[0]?.poNumber}</b> <br></br>
                                    {/* <b>Order ID</b> : #4 <br></br> */}
                                    <b>Order Date</b> : {orders[0]?.orderDate} <br></br>
                                    {/* <b>Account</b> : 1 <br></br> */}
                                    <b>Customer DEP ID</b>: {orders[0]?.customerId} <br></br>
                                </p>
                            </div>
                        </div>
                        {/* </Grid>
                        </Grid> */}
                        <br></br>
                        <div className="filterRow">
                            <div>
                                <FilterInput placeholder="Search by Device Id Or Asset Tag" icon={<FilterListIcon className="filterIconSize" />} value={search} onChange={handleSearch} />
                            </div>
                            <div></div>
                            <div></div>
                        </div>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>

                                    {stableSort(devices, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((device, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={device.deviceId}
                                                >
                                                    <TableCell>
                                                        <div className='d-flex'>{type == 2 && <Checkbox
                                                            checked={device.select === true}
                                                            style={{ color: "#F59300" }}
                                                            onChange={(event) => onChangeCheckedBox(event.target.checked, index)}
                                                        />}
                                                            <span className='mt-2'>{index + 1}</span>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {device.deviceId}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {device.assetTag}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="paginationLabel"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={devices.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                    <br></br>
                    <div >
                        <div className={`${classes.root} materialTableStyle`}>

                            <TableContainer>
                                <Table
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead1
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>

                                        {stableSort(transactionList, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((transaction, index) => {
                                                transaction.createdDate = transaction.createdDate && Moment(Date.parse(transaction.createdDate)).format('MM/DD/YYYY HH:mm:ss')
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={transaction.transactionId}
                                                    >
                                                        <TableCell>
                                                            {transaction.transactionId}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {transaction.orderType}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {transaction.depTransactionId}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {transaction.depStatus}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {statusCode}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {transaction.createdDate}
                                                            <div className="actionListEmailIconPosition">
                                                                <Tooltip title="Email" aria-label="Email">
                                                                    <IconButton className="actionBtn" aria-label="Email" onClick={() => handleClickOpen(transaction.id)}>
                                                                        <EmailIcon className="actionIcon" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Dialog open={open} closeonescape="true" fullWidth={true} maxWidth="sm" >
                                                                    <TableWrapper>
                                                                        <DialogTitle>
                                                                            <div className="text-right">
                                                                                <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={handleClose} />
                                                                            </div>
                                                                            Email
                                                                        </DialogTitle>
                                                                        <DialogContent className="dialogStyle">
                                                                            <div className={`${classes.root} materialTableStyle`}>
                                                                                <div className="filterRowUpdate d-flex justify-content-center">
                                                                                    <TextField fullWidth label="Email Id" variant="standard"
                                                                                        value={email}
                                                                                        onChange={e => setEmail(e.target.value)}
                                                                                    />
                                                                                    <span className="errMsg">{emailError}</span>
                                                                                    <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                                                                        style={{
                                                                                            borderRadius: "50%",
                                                                                            backgroundColor: "rgb(245, 147, 0)",
                                                                                            color: "white",
                                                                                            padding: "5px",
                                                                                            fontSize: "20px",
                                                                                            cursor: "pointer"
                                                                                        }} onClick={() => addemail()} />
                                                                                </div>
                                                                            </div>
                                                                            {displayemail()}
                                                                            <DialogActions>
                                                                                <Grid container spacing={4} className="mt-3">
                                                                                    <Grid item xs={12} sm={12} md={12} className="text-right">
                                                                                        <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={handleClose}>
                                                                                            Cancel
                                                                                        </Button>&nbsp;&nbsp;
                                                                                        <Button type="submit" disabled={disable} variant="contained" color="primary" className="noShadow addBtn" onClick={() => sendEmail()}>
                                                                                            Send
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </DialogActions>
                                                                            {message}
                                                                        </DialogContent>
                                                                    </TableWrapper>
                                                                </Dialog>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => navigate("/enrollment/error/" + transaction.transactionId)}>
                                                                Check Status
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className="paginationLabel"
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={orders.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                    <br></br>
                    {type != 0 &&
                        <div className="text-right">
                            <Button type="submit" variant="contained" color="primary" className="noShadow voidBtn" onClick={() => setVoidPopup(true)}>
                                {type == 1 ? "Void Order" : "Return Devices"}
                            </Button>
                            <Dialog open={voidPopup} closeonescape="true" maxWidth="true" >
                                <TableWrapper>
                                    <DialogContent className="dialogStyle">
                                        <div className={`${classes.root} materialTableStyle`}>
                                            {type == 1 ? "Are you sure you want to Void Order?" : "Are you sure you want to Return Devices?"}
                                        </div>
                                        <DialogActions>
                                            <Grid container spacing={4} className="mt-1">
                                                <Grid item xs={12} sm={12} md={12} className="text-center">
                                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setVoidPopup(false)}>
                                                        No
                                                    </Button>&nbsp;&nbsp;
                                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => handleOrder()}>
                                                        Yes
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </DialogActions>
                                        {message}
                                    </DialogContent>
                                </TableWrapper>
                            </Dialog>
                        </div>}
                </TableWrapper>
            }
        </div>
    );
}


