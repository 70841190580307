import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import CodeIcon from '@mui/icons-material/Code';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import GradeTwoTone from '@mui/icons-material/GradeTwoTone';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import Domain from '@mui/icons-material/Domain';
import KayakingIcon from '@mui/icons-material/Kayaking';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaidIcon from '@mui/icons-material/Paid';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListIcon: ListIcon,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  PeopleOutlined: PeopleOutlined,
  Domain: Domain,
  AccessTimeIcon: AccessTimeIcon,
  KayakingIcon: KayakingIcon,
  GroupsIcon: GroupsIcon,
  PaymentsIcon: PaymentsIcon,
  PaidIcon: PaidIcon,
  RadioButtonUncheckedIcon: RadioButtonUncheckedIcon
};


export default [
  {
    label: ' ',
    content: JSON.parse(
      `[   
          {
            "label": "Dashboard",
            "icon": "DashboardTwoToneIcon",
            "to": "/dashboard"
          },    
          {
            "label": "DEP Orders",
            "icon": "BarChartIcon",
            "to": "/order/list"
          },
          {
            "label": "DEP Enrollments",
            "icon": "RadioButtonUncheckedIcon",
            "to": "/order/enrollments"
          },
          {
            "label": "Clients",
            "icon": "PeopleOutlined",
            "to": "/customer/list"
          },
          {
            "label": "System Users",
            "icon": "PeopleOutlined",
            "to": "/user/list"
          }
]`,

      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }

  // {
  //   "label": "Order Details",
  //   "icon": "RadioButtonUncheckedIcon",
  //   "to": "/order/details"
  // },
  // {
  //   "label": "List Enrollments Errors",
  //   "icon": "RadioButtonUncheckedIcon",
  //   "to": "/enrollment/error"
  // }   
  // {
  //   "label": "Override Orders",
  //   "icon": "RadioButtonUncheckedIcon",
  //   "to": "/order/override"
  // }
  // {
  //   "label": "Customers",
  //   "icon": "PeopleOutlined",
  //   "children" : [
  //     {
  //       "label": "List Customers",
  //       "icon": "RadioButtonUncheckedIcon",
  //       "to": "/customer/list"
  //     }  
  //   ]}
]
