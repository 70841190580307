import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import EditIcon from '@mui/icons-material/Edit';
import style from 'styled-components';
import { styled, alpha } from '@mui/material/styles';
import { Button, Autocomplete, TextField } from '@mui/material';
import { FilterInput } from '../../../shared/components/FilterInput';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CancelIcon from '@mui/icons-material/Cancel';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Loader } from '../../../shared/components/Loader';
import FilterListIcon from '@mui/icons-material/FilterList';


const TableWrapper = style.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
         background-color: #F59300 !important;
    }
   
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListInfoIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListInfoIconPosition {
            position: absolute;
            top: 5px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
       
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 2fr 2fr 2fr 1fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterAutocomplete{
    
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
            height : 35px;
            padding-top : 0px;
        }
      
        .MuiFilledInput-input {
            padding: none;
            font-size: 12px;
            height : 10px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }}
    
    .textFieldStyle {

        background: white;
        padding: 4px;
        height: 35px;
        padding-top : 0px;

        .MuiInput-underline {
            borderBottom: none
        }
       

        .MuiInputBase-root {
            border-radius: 0;
            padding-top : 0px;
            margin-top : 10px;
        }
        
        .MuiInputBase-input {
            padding: none;
            font-size: 12px;
            height : 8px !important;
        }
        .MuiInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-root {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-root.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
        
    }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'id', numeric: false, label: 'Order ID' },
    { id: 'customerId', numeric: false, label: 'Customer DEP ID' },
    // { id: 'deporder', numeric: false, label: 'DEP Order' },
    // { id: 'enrollmentstatus', numeric: false, label: 'Enrollment Status' },
    { id: 'poNumber', numeric: false, label: 'P.O. Number' },
    { id: 'orderNumber', numeric: false, label: 'Order Number' },
    { id: 'customerEmail', numeric: false, label: 'Customer Email' },
    { id: 'orderDate', numeric: false, label: 'Order Date' },
    { id: '', numeric: false, label: '' }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function Orders() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [message, setMessage] = React.useState(" ");
    var [rows, setRows] = React.useState([]);
    const navigate = useNavigate();
    const token = config.TOKEN
    var [filteredRows, setFilteredRows] = React.useState([]);
    var [customerInfo, setCustomerInfo] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    var [startDate, setStartDate] = React.useState(Moment('2020-01-01').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = React.useState(Moment(Date.parse(new Date())).format('YYYY-MM-DD'));
    const [customerId, setCustomerId] = React.useState("0");
    const [poNumber, setPoNumber] = React.useState();
    var [orderNumber, setOrderNumber] = React.useState(0);
    const open = Boolean(anchorEl);
    var [isLoading, setIsLoading] = React.useState(false);
    var [deviceId, setDeviceId] = React.useState("");

    const handleClick = (event, orderNo) => {
        setAnchorEl(event.currentTarget);
        orderNumber = orderNo
        setOrderNumber(orderNo)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        // const lastMonth = new Date(endDate);
        // lastMonth.setMonth(lastMonth.getMonth() - 1);
        // startDate = Moment(Date.parse(lastMonth)).format('YYYY-MM-DD');
        // setStartDate(startDate);
        getOrders()
    }, [])

    const getOrders = () => {
        isLoading = true
        setIsLoading(isLoading)
        const MOBILESURETY_CUSTOMER_URL = config.API_URL + "/api/customerlist/basic"
        axios.get(MOBILESURETY_CUSTOMER_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    customerInfo = res.data.data
                    setCustomerInfo(customerInfo);
                    isLoading = false
                    setIsLoading(isLoading)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })

        const postData = {
            "startDate": startDate,
            "endDate": endDate,
            "customerId": customerId,
            "poNumber": poNumber
        }

        const MOBILESURETY_ORDER_URL = config.API_URL + "/api/Dep/orders"
        axios.post(MOBILESURETY_ORDER_URL, postData, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(filteredRows)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })
    }


    const handleSearch = (event) => {
        const postData = {
            "startDate": startDate,
            "endDate": endDate,
            "customerId": customerId,
            "poNumber": poNumber,
            "deviceId" : deviceId
        }

        const MOBILESURETY_ORDER_URL = config.API_URL + "/api/Dep/orders"
        axios.post(MOBILESURETY_ORDER_URL, postData, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(filteredRows)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            })

    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const StyledMenu = styled((props) => (

        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }
    ));

    const handleMenu = (orderNo) => {
        return (
            <div>
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(e) => handleClick(e, orderNo)}
                    endIcon={<KeyboardArrowDownIcon />}
                    style={{ backgroundColor: "#F59300" }}
                >
                    Actions
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => navigate("/order/details/" + orderNumber + "/2")} disableRipple>
                        <AssignmentReturnIcon />
                        Return Devices
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/order/override/'+ orderNumber )} disableRipple>
                        <EditIcon />
                        Override Order
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/order/details/" + orderNumber + "/1")} disableRipple>
                        <CancelIcon />
                        Void Order
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/order/details/" + orderNumber + "/0")} disableRipple>
                        <ListAltIcon />
                        Show Order Details
                    </MenuItem>
                </StyledMenu>
            </div>
        )

    }


    return (
        <div>
            {isLoading ? <Loader /> :
                <TableWrapper>
                    {message}
                    <div className={`${classes.root} materialTableStyle`}>
                        <div className="filterRow">
                            <Autocomplete
                                options={customerInfo}
                                className="filterAutocomplete"
                                getOptionLabel={(option) => option.name}
                                onChange={(event, client) => {
                                    client != null ? setCustomerId(client.id) : setCustomerId(-1)
                                }}
                                renderInput={(params) => <TextField  {...params} fullWidth label="Select Customer" className="filter" variant="filled" />
                                }
                            />
                            <div className="textFieldStyle">
                                <TextField variant='standard' label="P.O. Number" value={poNumber} onChange={e => setPoNumber(e.target.value)} InputProps={{ disableUnderline: true }} />
                            </div>
                            <div>
                                <FilterInput style={{ height: "35px" }} placeholder="Device Id" value={deviceId} onChange={(event) => setDeviceId(event.target.value)} />
                            </div>
                            <div>
                                <FilterInput style={{ height: "35px" }} placeholder="Start Date(yyyy-mm-dd)" type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                            </div>
                            <div>
                                <FilterInput style={{ height: "35px" }} placeholder="End Date(yyyy-mm-dd)" type="date" value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                            </div>
                            <div>
                                <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={handleSearch} >
                                    Search
                                </Button>
                            </div>
                        </div>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>

                                    {stableSort(filteredRows, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((filteredRows, index) => {
                                            filteredRows.orderDate = filteredRows.orderDate && Moment(Date.parse(filteredRows.orderDate)).format('MM/DD/YYYY')
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={filteredRows.id}
                                                >
                                                    <TableCell >
                                                        {filteredRows.id}
                                                    </TableCell>
                                                    <TableCell >
                                                        {filteredRows.customerId}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {filteredRows.poNumber}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {filteredRows.accountRep}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {filteredRows.customerEmail}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {filteredRows.orderDate}
                                                    </TableCell>
                                                    {/* <TableCell align="left">
                                                {filteredRows.accountrep}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.customeremail}
                                            </TableCell>
                                            <TableCell align="left">
                                                {filteredRows.orderdate}
                                            </TableCell>*/}
                                                    <TableCell align="left">
                                                        {handleMenu(filteredRows.orderNumber)}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="paginationLabel"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </TableWrapper>
            }
        </div>
    );
}


